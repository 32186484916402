import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import PaymentChoice from "../../organisms/PaymentChoice/PaymentChoice";
import Footer from "../../templates/Footer/Footer";
import Header from "../../templates/Header/Header";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { useLazyCreateHipayCardQuery } from "../../../rtk/payment/payment";
import { NewHipayMeanBody } from "../../../types/rtk/payment/mean";
import { useLazyPayOrderQuery } from "../../../rtk/order/order";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { AppRoutes } from "../../../routes/AppRoutes";
import { setBasket } from "../../../rtk/basket/basket";

const Payment = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const basket = useAppSelector((state: RootState) => state.basket);
  const order_ref = useAppSelector(
    (state: RootState) => state.order.reference
  );
  const [createCardCall] = useLazyCreateHipayCardQuery();
  const [payOrderCall] = useLazyPayOrderQuery();
  const [isLoading, setIsLoading] = useState(false);

  if (basket.subscription === null)
    navigate(AppRoutes.SUBSCRIPTIONS);
  else if (basket.options === null)
    navigate(AppRoutes.OPTIONS);

  const handleHipay = async (response: any) => {
    setIsLoading(true);
    const data: NewHipayMeanBody = {
      name: `Card ${response.pan}`,
      reference: response.token,
      reference_type: response.payment_product,
      user_hint: response.pan,
      expiration: (new Date(
        parseInt(response.card_expiry_year),
        parseInt(response.card_expiry_month)
      )).toISOString(),
      provider: 'weenect_eur',
      browser_info: response.browser_info
    };
    console.log(data);
    await dispatch(setBasket({ ...basket, isPaymentInformationsOk: false }));
    let resp: any = await createCardCall({body: data});
    if (resp.isError){
      console.log(resp.error);
      return
    }
    const payment_mean_id = resp.data.id;
    resp = await payOrderCall({ref: order_ref!, body: {payment_mean: payment_mean_id, language: i18n.language}});
    if (resp.isError){
      console.log(resp.error);
      return
    }
    const target_url: string = resp.data;
    if (target_url.startsWith("/")){
      navigate(target_url);
    } else {
      window.location.href = target_url;
    }
    setIsLoading(false);
  }

  const handleGocardless = async () => {
    setIsLoading(true);
    await dispatch(setBasket({ ...basket, isPaymentInformationsOk: false }));
    const resp = await payOrderCall({ref: order_ref!, body: {payment_provider: 4, language: i18n.language}});
    if (resp.isError){
      console.log(resp.error);
      return
    }
    const target_url: string = resp.data!;
    if (target_url.startsWith("/")){
      navigate(target_url);
    } else {
      window.location.href = target_url;
    }
    setIsLoading(false);
  }

  const render_button = () => {
    return (basket.paymentMethodSelected === 'card' ? <StyledButton
        children={t("subscription:THIRD_PAGE.BUTTON")}
        id="hipay-submit-button"
        type="submit"
        form="hipay-form"
        disabled={!basket.isPaymentInformationsOk}
        //onClick={() => { if (basket.isPaymentInformationsOk) return; }}
      /> : 
      <StyledButton
          children={t("subscription:THIRD_PAGE.BUTTON")}
          disabled={!basket.isPaymentInformationsOk}
          onClick={() => {handleGocardless()}}
        />)
  }
  
  return (
    <>
      <Header
        headerStep={3}
        title={t("subscription:HEADER.TITLE.THIRD_PAGE")}
        goBack={() => navigate(AppRoutes.OPTIONS)}
      />
      <Box className="Payment">
        <PaymentChoice handleHipay={handleHipay} />
      </Box>
      <Footer headerStep={3}>
        {(isLoading) ? <SpinnerLoader />
        : render_button()}
      </Footer>
    </>
  );
};

export default Payment;
