import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LogoSvg from "../../atoms/LogoSvg/LogoSvg";
import FAIL from "../../../assets/images/FAIL.svg";
import Text from "../../atoms/Text/Text";
import Title from "../../atoms/Title/Title";
import Footer from "../../templates/Footer/Footer";
import Header from "../../templates/Header/Header";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { AppRoutes } from "../../../routes/AppRoutes";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { useEffect, useState } from "react";
import { useLazyValidateOrderQuery } from "../../../rtk/order/order";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";

const MAX_PAYMENT_REFRESH = 25;
// const ALLOW_CANCEL_REFRESH = 5;

const Pending = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(0);
  const [timedOutRefresh, setTimedOutRefresh] = useState(0);
  const [validationCall] = useLazyValidateOrderQuery();
  const order_ref = useAppSelector(
    (state: RootState) => state.order.reference
  );

  useEffect(() => {
    if (order_ref) {
      validationCall({ref: order_ref}).then((resp) => {
        if (resp.isError) {
          console.log(resp.error);
          if (resp.status) {
            navigate(AppRoutes.FAIL);
          }
        } else {
          if (resp.data === 'success'){
            navigate(AppRoutes.SUCCESS);
          } else if (resp.data === 'error') {
            navigate(AppRoutes.FAIL);
          } else {
            if (refresh < MAX_PAYMENT_REFRESH) {
              // We wait longer to refresh each time
              const delay = Math.max(Math.min(500 * refresh, 5000), 500);
              setTimeout(() => setRefresh((state) => state + 1), delay);
            } else {
              // Something probably went wrong, we redirect to unknown state
              // navigate(AppRoutes.UNKNOWN);

            }
          }
        }
      });
    }
  }, [order_ref, refresh, navigate, validationCall])

  return (
    <>
      <Header headerStep={4} title={t("subscription:PENDING_PAGE.TITLE")} />
      <Box className="Pending">
        <Box className="content">
          {timedOutRefresh ? <LogoSvg className="Logos" src={FAIL} />
          : <SpinnerLoader size={100} style={{"height": "auto"}} />}
          <Title
            variant="h1"
            children={t("subscription:PENDING_PAGE.TITLE")}
          />
          <Text children={t("subscription:PENDING_PAGE.TEXT")} />
        </Box>
      </Box>
      <Footer headerStep={4}>
        <StyledButton
          children={t("subscription:PENDING_PAGE.BUTTON")}
          disabled={true}
          // disabled={refresh < ALLOW_CANCEL_REFRESH}
        />
      </Footer>
    </>
  );
};

export default Pending;
